export const onBoarding = {
  hello: {
    title: '你好!',
    subTitle: 'Hello!',
  },
  logIn: {
    title: '登入',
    subTitle: 'LOGIN',
  },
  noAccount: {
    title: '如沒有戶口請按這裡',
    subTitle: `Don't have an account?`,
  },
  signUp: {
    title: '申請帳戶',
    subTitle: `Sign up`,
  },
  welcome: {
    title: '歡迎回來',
    subTitle: 'Welcome back',
  },
  termsAndConditionDialog: {
    title: '使用條款及細則\nTerms & Conditions',
    confirmationMessageZH: '我已閱讀並同意',
    confirmationMessageEN: 'I have read and accept the ',
    termsAndConditionZH: '使用條款及細則',
    termsAndConditionEN: 'Terms and Conditions',
  },
  screenOnStart: {
    '0': {
      title: `嶄新的\n文化體驗`,
      subTitle: `A Brand New\nCultural Experience`,
    },
    '1': {
      title: `虛擬 X 實境\n解謎之旅`,
      subTitle: `Virtual X Reality\nRiddle Journey`,
    },
    '2': {
      title: `體驗香港各個\n小島風情`,
      subTitle: `Experience\nUnique Islands of\nHong Kong`,
    },
  },

  continue: { title: '繼續', subTitle: 'NEXT' },
}
