export const error = {
  unrecognized: {
    title: 'Error',
    content:
      'Something went wrong. Please try again. If the problem persists, please contact technical support. ({{error}})',
  },

  errorDetail: 'Error: {{codes}}\nTrace code: {{traceId}}',

  action: { retry: 'Retry' },
  badRequest: {
    title: 'Error request',
    content:
      'We are having a request error, please try again. If the problem persists, please contact technical support. ({{error}})',
  },
  cannotConnect: {
    title: 'No network connection',
    content: 'Failed to connect to network ',
  },
  default: {
    title: 'Error',
    content:
      'Something went wrong. Please try again. If the problem persists, please contact technical support. ({{error}})',
    contentWithError: '$t(error.default.content) ({{error}})',
  },
  forbidden: {
    title: 'No permission',
    content: 'You have no access right',
  },
  notFound: {
    title: 'Unable to find',
    content: 'The data you wish to access cannot be found.',
  },
  serverError: {
    title: 'Server error',
    content:
      'We are having an internal error, please try again. If the problem persists, please contact technical support. ({{error}})',
  },
  timeout: {
    title: 'Connection timeout',
    content: 'Connection timeout',
  },
  unauthorized: {
    title: 'Not verfied',
    content: 'Your verification has expired',
  },
  loadSystemParameter: {
    title: 'Error',
    content: 'Unable to access System Parameters, Please try again later.',
  },
  loadFile: {
    title: 'Error',
    content: 'Unable to access Content, Please try again later.',
  },
}
