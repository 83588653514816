export const hint = {
  title: 'Hints',
  content:
    "If you find the riddle too difficult, you can use the 'Hints' as shown as this icon on screen. There are different levels of hints; 'Basic Hints', 'Advanced Hints' and 'Reveal Answer'. Try your best to answer before using any hints.",
  warning: 'Use the hints with caution. \nIt may impact your game performance!',
  hint: 'Hint {{number}}',
  useHint: 'Hint {{number}}',
  basicHint: 'Basic Hint',
  advanceHint: 'Advance Hint',
  showAnswer: 'Reveal Answer',
  returnButton: 'Continue',
}
