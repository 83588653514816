import { assemble } from './assemble'
import { badge } from './badge'
import { characterSelection } from './character-selection'
import { common } from './common'
import { hint } from './hint'
import { imageRecognition } from './image-recognition'
import { riddleTutorial } from './riddle-tutorial'
import { textInput } from './text-input'
import { video } from './video'
import { score } from './score'

export const game = {
  assemble,
  characterSelection,
  common,
  hint,
  badge,
  imageRecognition,
  riddleTutorial,
  textInput,
  video,
  score,
}
