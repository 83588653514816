export const home = {
  version: '{{env}} 版本 {{version}}',
  enterButton: {
    title: '進入',
    subTitle: 'ENTER',
  },
  riddle: {
    title: '解謎遊戲',
    subTitle: 'RIDDLE GAMES',
    description: '透過解謎遊戲發掘各區的故事，\n跟阿抌與鍾bell展開路程吧！',
  },
  cheungChau: {
    title: '長洲',
    subTitle: 'CHEUNG CHAU',
  },
}
