export const common = {
  add: 'Add',
  back: 'Back',
  cancel: 'Cancel',
  change: 'Change',
  clear: 'Clear',
  clickToRetry: 'Retry',
  confirm: 'Confirm',
  continue: 'Continue',
  continueBilingual: { title: '繼續', subTitle: 'Continue' },
  play: { title: '開始', subTitle: 'Play' },
  copiedToClipboard: {
    error: 'Unable to access the clipboard. \n({{error}})',
    success: 'The content has been copied to your clipboard',
  },
  delete: 'Delete',
  discard: 'Discard',
  download: 'Download',
  edit: 'Edit',
  empty: 'Empty',
  emptyList: 'This list is empty',
  errorMessage: 'System error. \n{{error}}',
  format: {
    date: 'DD/MM/YYYY',
    datetime: 'DD/MM/YYYY HH:mm',
    kendoDateInput: 'dd/MM/yyyy',
  },
  from: 'From',
  generate: 'Export Data into Excel',
  login: 'Login',
  logout: 'Logout',
  signUp: 'Sign up',
  next: 'Next',
  no: 'No',
  noRecordsAvailable: 'No record found',
  obsolete: 'Obsolete',
  ok: 'OK',
  post: 'Post',
  reset: 'Reset',
  retry: 'Retry',
  save: 'Save',
  scan: 'Scan',
  search: 'Search',
  submit: 'Submit',
  submitted: 'Submitted',
  to: 'To',
  unrecognizedErrorTitle: 'Error',
  unrecognizedErrorTitleWithErrorCodes: 'Error: {{errorMessage}}',
  update: 'Update',
  version: 'Version {{version}} / {{codePushVersion}}',
  view: 'View',
  yes: 'Yes',
}
