export const setting = {
  title: '設定',
  menu: {
    passwordSetting: '密碼設置',
    termsAndConditions: '條款及細則',
  },
  editProfile: '編輯資料',
  addProfilePicture: '新增頭像',
  userName: '用户名稱 User Name',
  uploadAvatar: {
    error: {
      title: '新增頭像失敗',
    },
  },
  accountControl: {
    deleteAccount: {
      actionButtonTitle: '刪除賬户?',
      title: '刪除賬户?',
      content: '刪除帳號後，你將無法再使用該帳戶登入，確定要繼續嗎？',
    },
  },
  username: '用戶名稱',
  email: '電郵',
}
