export const qrCodeScanner = {
  scannerModal: {
    title: '遊戲工具包',
    content: `此遊戲需要配合實體工具包進行體驗。\n如未購買，歡迎到長洲戲院購買。如已購買，請掃下方QR Code開始遊戲。`,
    note: '請注意：此遊戲只提供繁體中文版本',
    badge: '中文版限定',
  },
  noPermission: {
    content: '允許應用程式使用相機掃描二維碼。',
    openSettings: '打開設置',
    title: '應用程式想要使用設備的鏡頭權限',
  },
  noPermissionPlaceholder: '沒有設備的鏡頭權限',
  invalidCode: {
    title: '二維碼錯誤',
    content: '二維碼不能被認證，請重新掃描',
  },
  codeInUse: {
    title: '二維碼已被使用',
    content: '此遊戲工具包已被使用',
  },
  duplicatedCharacter: {
    title: '角色重複',
    content: '玩家們需選擇不同角色！',
  },
  gameEnded: {
    title: '二維碼重複',
    content: '二維碼只能使用一次，請使用有效的二維碼',
  },
  wrongGameKit: {
    title: '二維碼重複',
    content: '此為另一遊戲階段的二維碼，請掃瞄本遊戲階段的二維碼',
  },
}
