export const imageRecognition = {
  start: '開始任務',
  'instruction-recognition': '留意四周，把相機對準這個形狀的物件，便可過關。',
  'hint-360': '左右滑動畫面尋找物件',
  'instruction-360': '點擊這個形狀的物件，便可完成任務。',
  completed: '找到啦！這就是建造包山的材料其中之一',
  item: '物件{{index}}:',
  videoInstruction: '請把相機對準掃描地圖',
  videoDialog: {
    title: '最終回',
    description: '點擊觀看結局',
  },
  itemCount: '({{count}}/{{total}})',
}
