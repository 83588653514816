export const aboutUs = {
  title: '關於我們',
  ccisland: `
「島聚」是一個結合虛擬和實境互動的手機應用程式（App），以一個嶄新角度重新體驗香港不同的傳統文化。「島聚」現階段提供截然不同的戶外遊戲體驗給予玩家，玩家通過手機加入遊戲包、以朋友組團或家庭代入故事主人翁，透過解謎及尋找線索以完成任務，以富趣味形式遊歷歷史悠久的建築物及店舖。

如果您有任何問題或需要進一步了解我們的應用程式，請隨時與我們聯繫3612 9086或電郵至 info@knighthoodevent.com 查詢。

  `,
  regions: `
  香港仔中心
香港仔中心位處香港仔繁盛之市中心，為南區最大型的優閒購物熱點。商場由一至五期組成，總面積約32萬平方呎，設有約200間商舖，匯聚各式時裝精品、運動用品、家居用品、都薈美食等，為您提供購物娛樂的好去處。

長洲
玩家將化身為見習神明，從不一樣的角度回看長洲的過去，感受身處的現在，以及想像長洲今後的所往。
遊戲路線多樣化，無論與家人或朋友同行都可以好好遊歷長洲這個歷史文化氣息濃厚的小島。

  `,
}
