export const error = {
  unrecognized: {
    title: '錯誤',
    content: '發生未知的錯誤，請稍後再試。如持續發生，請聯絡技術支援。',
  },

  errorDetail: '錯誤代號: {{errorCodes}}\n錯誤追跡碼: {{traceId}}',

  action: {
    retry: '重試',
  },
  badRequest: {
    title: '請求錯誤',
    content: '發生錯誤，請稍後再試',
  },
  cannotConnect: {
    title: '未有網絡',
    content: '未能連接到伺服器。請確定你的網絡信號良好並再試。',
  },
  default: {
    title: '錯誤',
    content: '系統錯誤，請稍後再試',
  },
  forbidden: {
    title: '不允許操作',
    content: '你未被允許進行相關操作。請確認你的權限後再試。',
  },
  notFound: {
    title: '未找到記錄',
    content: '你所查找的記錄不存在。',
  },
  serverError: {
    title: '伺服器錯誤',
    content: '伺服器錯誤，請稍後再試',
  },
  timeout: {
    title: '連線逾時',
    content: '連接到伺服器逾時。請確定你的網絡信號良好並再試。',
  },
  unauthorized: {
    title: '認證過期',
    content: '你的認證已過期。請重新登入。',
  },
  loadSystemParameter: {
    title: '系統異常',
    content: '無法獲取所需資料，請稍候再試',
  },
  loadFile: {
    title: '系統異常',
    content: '無法獲取所需資料，請稍候再試',
  },
}
