export const aboutUs = {
  title: 'ABOUT US',
  ccisland: `
  "Island Together" is an app that combines virtual and augmented reality interactions to offer a fresh perspective on the traditional culture of Hong Kong. "Island Together" currently provides players with a completely different gaming experience, allowing them to form teams with friends or family to explore the traditional features, such as historical buildings and shops, through various levels and missions.

If you have any questions or would like to learn more about our application, please feel free to contact us at 3612 9086 or email to info@knighthoodevent.com. 
  `,
  regions: `AC is the largest leisure shopping hot spot at the prosperous city center of Aberdeen, Southern District. The arcade is composed of five sites, spanning across 320,000 sq.ft. with 200 shops approximately. It renders a brilliant spectrum of shopping, from stylish fashion, sports outlets, to household products and popular cuisines.

Cheung Chau 
Get ready to become an apprentice deity and take a fresh look at the island's past, experience the present moment, and imagine what lies ahead for Cheung Chau.
Players can join the game through their smartphones, forming teams with friends or playing as a family to immerse themselves in the role of the story's protagonists. By solving puzzles and searching for clues, they'll complete missions and explore the entirety of Cheung Chau in an engaging and entertaining way.
`,
}
