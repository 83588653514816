import { aboutUs } from './about-us'
import { common } from './common'
import { error } from './error'
import { faq } from './faq'
import { game } from './game'
import { history } from './history'
import { home } from './home'
import { mainMenu } from './main-menu'
import { onBoarding } from './on-boarding'
import { permission } from './permission'
import { privacyPolicy } from './privacy-policy'
import { qrCodeScanner } from './qr-code-scanner'
import { setting } from './setting'

export const en = {
  aboutUs,
  common,
  error,
  faq,
  game,
  history,
  home,
  mainMenu,
  onBoarding,
  permission,
  privacyPolicy,
  qrCodeScanner,
  setting,
}
