export const setting = {
  title: 'SETTINGS',
  menu: {
    passwordSetting: 'Password Settings',
    termsAndConditions: 'Terms & Conditions',
  },
  editProfile: 'Edit Profile',
  addProfilePicture: 'Add profile picture',
  userName: '用户名稱 User Name',
  uploadAvatar: {
    error: {
      title: 'Failed to add profile picture',
    },
  },
  accountControl: {
    deleteAccount: {
      actionButtonTitle: 'Delete Account?',
      title: 'Delete Account',
      content:
        'You will lose all your data by deleting your account. This action cannot be undone.',
    },
  },
  username: 'User Name',
  email: 'Email',
}
