export const faq = {
  title: 'Frequently Asked \nQuestions',
  content: `Q1/ Can I reuse my QR code?
A: QR codes can only be used once and are provided for two players at a time. If you'd like to experience the game again, feel free to purchase another game package!
  
Q2/ Why did I pass a riddle without entering an answer?
A: Some riddles in the game require cooperation between two players. If one player successfully completes a riddle, the other player will automatically pass as well.
  
Q3/ Why is the game progress different after reopening the app?
A: The inconsistent game progress after reopening the app may be related to the situation mentioned in Question 2. You and your teammate may be on different pages of the same riddle. If you and your teammate are in different riddles and unable to continue the game, please reopen the app together.

Q4/ Why can't I use my camera/GPS function?
A: This game requires permission to access the camera and location services in order to function properly. Please check the relevant settings in your phone's application.

Q5/ I have reached the destination but can't assemble with my teammate?
A: If you and your teammate reach the destination in the game but cannot assemble, it may be due to the GPS function not being enabled or experiencing issues. Please check your phone's settings. The GPS function can also be unstable due to weather conditions. You and your teammate can try restarting the app or using the "Arrived?" prompt in the game's assemble page to continue.

Q6/: What should I do if I lose/damage game components during gameplay?
A: If you unfortunately lose or damage game components during gameplay, please bring the game package back to the inquiry counter for further assistance.

Q7/ Why is the display on my tablet device incomplete?
A: This application is designed for iOS and Android smartphones, so it may not display properly on tablet devices. For the best experience, please use a smartphone to access the app.

Q8/ Why does the game video stutter or stop playing?
A: Issues with game videos may be related to network speed and signal reception. Please check your phone's network signal.

Q9/ If I have more questions, what can I do?
A: You may encounter various types of issues during the game. You can try the following solutions to resolve your problem:
．Restart the app.
．Allow the necessary permissions for the app.
．Update your phone's system version.
．Close background apps that consume network speed or memory.
．Clear the cache and data on your phone.
．Check your network connection.
`,
}
