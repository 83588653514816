export const home = {
  version: '{{env}} Version {{version}}',
  RegionButton: {
    titleAberdeen: '香港仔',
    subTitleAberdeen: 'Aberdeen',
    titleCheungChau: '長洲',
    subTitleCheungChau: 'Cheung Chau',
  },
  riddle: {
    title: '解謎遊戲',
    subTitle: 'RIDDLE GAMES',
    desp: '透過解謎遊戲發掘各區的故事，\n跟阿抌與鍾bell展開路程吧！',
  },
  cheungChau: {
    title: '島聚',
    subTitle: 'ISLAND TOGETHER',
  },
}
