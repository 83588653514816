export const hint = {
  title: '使用提示',
  content:
    '如覺得謎題太困難，可以按畫面上的「使用提示」。提示分為「初階提示」、「進階提示」和「揭曉答案」。在獲得「進階提示」或「揭曉答案」之前，請先嘗試回答。',
  warning: '請小心使用提示，使用提示的\n次數會影響遊戲成績！',
  hint: '提示{{numberZh}}',
  useHint: '獲得提示{{numberZh}}',
  basicHint: '初階提示',
  advanceHint: '進階提示',
  showAnswer: '揭曉答案',
  returnButton: '繼續作答',
}
