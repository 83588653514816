export const faq = {
  title: '常見問題',
  content: `Q1/ 我的QR code可以重用嗎？
A: QR code只能使用一次，每次只提供兩位玩家使用。如果想再次體驗，歡迎您再次購入遊戲包喔！
  
Q2/ 我在未輸入答案時就已過關，為什麼？
A: 遊戲部份關卡是以二人合作方式過關，如果其中一位玩家順利過關， 另一位亦會自動過關。
  
Q3/ 軟件重新開啟後進度不一，怎麼辦？
A: 軟件重新開啟後進度不一可能與問題2情況有關。您與隊友有可能處於相同關卡中的不同頁面，如果您與隊友處於不同關卡而導致不能繼續遊戲，請您與隊友一同重新開啟應用程式。
  
Q4/ 我的相機或GPS功能不能使用，為什麼？
A:本遊戲需要允許啟動相機及定位系統的權限方能正常運作，請先檢查手機應用程式的相關設定。
  
Q5/ 我已到達目的地，但不能集合隊友？
A: 如果您和隊友在遊戲中到達目的地，但未能集合，可能是您或隊友的定位功能並未開啟或出現問題，請先檢查手機設定。定位系統亦可能因天氣狀況而出現不穩定，您或隊友可嘗試重啟應用程式，或使用遊戲集合頁面中「已到達？」的嘗試繼續遊戲。
  
Q6/ 如果我在遊戲中途遺失/損壞遊戲配件，怎麼辦？
A: 如果您不幸初遊戲途中遺失或損壞遊戲配件，請帶同遊戲包回詢問處作進一步處理。
  
Q7/ 為什麼我的平板裝置顯示的畫面不完整？
A: 本應用程式專為IOS及Android系統之手機設計， 平板裝置有可能顯示不完整。為了優質的體驗，請使用手機進入應用程式。
  
Q8/ 為什麼遊戲影片播放途中會出現卡頓或停上播放？
A: 遊戲影片出現問題可能與網絡速度及訊號接收環境有關，請檢查手機的網絡訊號。
  
Q9/ 如果我有更多其他問題，可以怎麼辦？
A: 您可能在遊戲中途與上更多不同類型的問題，可以嘗試以下方案看看能否解決您的問題：
．重新啟動應用程式；
．允許應用程式所需權限；
．更新手機系統版本；
．釋放後台應用，關閉佔用網速較多或消耗內存較多的程式；
．清理手機緩存和數據；
．檢查網絡狀況
  
`,
}
