export const assemble = {
  assembleTitle: '到達目的地',
  nextDestinationTitle: '下一站',
  suggestedRoute: '參考路線',
  mapButtonHint: '點撃標示開啟地圖',
  assemble: '小隊集合',
  waiting: '集合隊友...',
  assembled: '全隊集齊！',
  overrideButton: '已到達?',
  overrideGPSDialog: {
    title: '已到達目的地？',
    content: '確認到達目的地將不能返回上一頁',
  },
}
