export const qrCodeScanner = {
  scannerModal: {
    title: 'Game Kit',
    content: `This game requires a physical game kit for the complete experience. If you haven't purchased it yet, you're welcome to buy it at Cheung Chau Cinema. If you have already purchased it, please scan the QR code below to start the game.`,
    note: '請注意：此遊戲只提供繁體中文版本',
    badge: 'Available in\n Chinese only',
  },
  noPermission: {
    content: 'Allow the app to scan QR code with the camera.',
    openSettings: 'Open Settings',
    title: 'The app Would Like to Access the Camera',
  },
  noPermissionPlaceholder: 'No camera permission',
  invalidCode: {
    title: 'Invalid QR code',
    content: 'The QR code cannot be recognized, please scan again',
  },
  codeInUse: {
    title: 'QR code Redeemed',
    content:
      'This game kit serial has already been redeemed and should not be used again',
  },
  duplicatedCharacter: {
    title: 'Duplicated Character',
    content: 'Player needs to select different character',
  },
  gameEnded: {
    title: 'QR code duplication',
    content: 'The QR code can only be used once, please use valid QR code',
  },
  wrongGameKit: {
    title: 'QR code duplication',
    content:
      'This is the QR code for another stage of the game. Please scan the correct QR code for this stage.',
  },
}
