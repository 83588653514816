export const assemble = {
  assembleTitle: 'Arrival',
  nextDestinationTitle: 'Next Location',
  suggestedRoute: 'Reference\nRoute',
  mapButtonHint: 'Click to open the map',
  assemble: 'Assemble',
  waiting: 'Waiting for teammate...',
  assembled: 'Assembled!',
  overrideButton: 'Arrived?',
  overrideGPSDialog: {
    title: 'Do you actually arrive?',
    content:
      "Once you confirm reaching the destination, you won't be able to go back to the previous page",
  },
}
